<template>
  <div class="height1">
    <!--      头部      -->
    <div class="iq-top-navbar">
      <nav class="navbar navbar-expand-lg">
        <head-center></head-center>
      </nav>
    </div>
    <!--      内容          -->
    <div class="center-content">
      <el-row :gutter="15">
        <el-col :span="17">
          <div class="leftContent">
            <el-card class="cart-c">
              <el-row :gutter="30">
                <el-col :span="14" v-if="isShowBlance">
                  <div class="flex-space-between">
                    <div class="box b1 cursor" @click.stop="jumpPage('/Finance/BalanceDetail')">
                      <div>账户余额(元)：</div>
                      <div class="text-c num">
                        <countTo :startVal="0" :endVal="indexData.balance" :decimals="2" :duration="1000"> </countTo>
                      </div>
                      <div class="bottom-btn" @click="jumpPage('/Finance/Withdrawal')"><span>充值</span><span>提现</span></div>
                    </div>
                    <div class="box b2 flex-center-c cursor" @click="jumpPage('/Finance/BalanceDetail')">
                      <div class="text-c num">
                        <countTo :startVal="0" :endVal="indexData.waitMoney" :decimals="2" :duration="1000"> </countTo>
                      </div>
                      <div class="text-c">未入账金额(元)</div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="10" v-if="isShowOutpatient">
                  <div class="top-right flex-space-between" style="cursor: pointer" @click="jumpPage('/Outpatient/STATS/IncomeStat')">
                    <!-- <div class="flex-center-c" v-if="isShowBlance && isShowOutpatient"><div class="line1"></div></div> -->
                    <div class="flex-center-c t1" style="width: 60%">
                      <div class="text-c">门诊收入金额(元)</div>
                      <div class="text-c num">
                        <countTo :startVal="0" :endVal="indexData.indexPriceIn.price" :decimals="2" :duration="1000"> </countTo>
                      </div>
                      <div class="text-c t2">订单总金额</div>
                    </div>
                    <div class="" style="width: 40%">
                      <div class="flex-start" style="width: 100%">
                        <span class="dian1"></span>
                        <span class="n1">处方费</span>
                        <span class="num2">{{ Number(indexData.indexPriceIn.patientMoney).toFixed(2) }}</span>
                      </div>
                      <div class="flex-start" style="width: 100%">
                        <span class="dian2"></span>
                        <span class="n1">代煎费</span>
                        <span class="num2">{{ Number(indexData.indexPriceIn.tcmDecoctionMoney).toFixed(2) }}</span>
                      </div>
                      <div class="flex-start" style="width: 100%">
                        <span class="dian3"></span>
                        <span class="n1">快递费</span>
                        <span class="num2">{{ Number(indexData.indexPriceIn.expressMoney).toFixed(2) }}</span>
                      </div>
                      <div class="flex-start" style="width: 100%">
                        <span class="dian4"></span>
                        <span class="n1">附加费</span>
                        <span class="num2">{{ Number(indexData.indexPriceIn.appendMoney).toFixed(2) }}</span>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-card>
            <div class="title-samll" v-if="isShowOutpatient">门诊数据</div>
            <el-card class="cart-c mt" v-if="isShowOutpatient">
              <div class="flex-space-between">
                <div class="box b3 bc">
                  <div class="t3">医生总人数</div>
                  <div class="t4">{{ indexData.doctorNum }}</div>
                </div>
                <div class="box b4 bc">
                  <div class="t3">审核医生总人数</div>
                  <div class="t4">{{ indexData.auditDoctorNum }}</div>
                </div>
                <div class="box b5 bc">
                  <div class="t3">患者总人数</div>
                  <div class="t4">{{ indexData.patientNum }}</div>
                </div>
              </div>
            </el-card>
            <div class="title-samll flex-space-between" v-if="isShowOutpatient">
              <div>
                <span>医生开方数TOP5</span>
                <i class="iconfont icon-shuaxin" style="margin: 0 30px 0 10px; cursor: pointer" @click="getAuditIndex"></i>
                <span @click="jumpPage('/Outpatient/STATS/DoctorSalesStat')" style="cursor: pointer">
                  <span style="font-size: 14px; color: #3f75ff">查看更多</span>
                  <i class="el-icon-arrow-right" style="font-size: 14px; color: #3f75ff"></i>
                </span>
              </div>
              <div class="flex-space-between">
                <div class="tabName" :class="activeIndex == 0 ? 'currentTitle' : ''" @click="tabClick(0)">今天</div>
                <div class="tabName" :class="activeIndex == 1 ? 'currentTitle' : ''" @click="tabClick(1)">本周</div>
                <div class="tabName" :class="activeIndex == 2 ? 'currentTitle' : ''" @click="tabClick(2)">本月</div>
                <div class="tabName" :class="activeIndex == 3 ? 'currentTitle' : ''" @click="tabClick(3)">本年</div>
              </div>
            </div>
            <el-card class="cart-c mt">
              <el-table :data="indexData.makeData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" height="420px" v-if="indexData.makeData.length>0">
                <el-table-column type="index" label="编号" width="50"></el-table-column>
                <el-table-column prop="doctorName" label="医生">
                  <template slot-scope="scope">
                    <p>{{ scope.row.doctorName }}</p>
                    <p>{{ scope.row.doctorPhone }}</p>
                  </template>
                </el-table-column>
                <el-table-column prop="department" label="所属部门" align="center"></el-table-column>
                <el-table-column prop="makeDrugNum" label="开方数量(个)" align="center"></el-table-column>
                <el-table-column prop="price" label="订单总额(元)" align="center"> </el-table-column>
              </el-table>
              <el-empty :image-size="110" v-else style="height: 430px;"></el-empty>
            </el-card>
          </div>
        </el-col>
        <el-col :span="7" v-if="isShowOutpatient">
          <div class="rightContent">
            <el-card class="cart-c">
              <div class="box flex-center-c" style="cursor: pointer" @click="jumpPage('/Outpatient/STATS/RefundStat')">
                <div class="text-l" style="width: 100%; font-weight: 800">门诊退费金额(元)</div>
                <div class="text-c num">
                  <countTo :startVal="0" :endVal="indexData.indexIsRefund" :decimals="2" :duration="1000"> </countTo>
                </div>
                <div class="text-c">已退费金额</div>
              </div>
            </el-card>
            <el-card class="cart-c">
              <div class="cTitle">门诊收入(元)</div>
              <div id="c1" ref="chart1" style="width: 100%; height: 200px" v-if="indexData.indexDrugIn.length>0"></div>
              <el-empty :image-size="110" v-else></el-empty>
              <div class="cTitle">门诊接诊次数</div>
              <div id="c2" ref="chart2" style="width: 100%; height: 180px" v-if="indexData.indexDrugReception.length>0"></div>
              <el-empty :image-size="110" v-else></el-empty>
              <div class="cTitle flex-space-between">
                <p>
                  医生审方数TOP5
                  <span @click="jumpPage('/Outpatient/STATS/ReviewerFeesStat')" style="cursor: pointer">
                    <span style="font-size: 14px; color: #3f75ff">查看更多</span>
                    <i class="el-icon-arrow-right" style="font-size: 14px; color: #3f75ff"></i>
                  </span>
                </p>
                <el-select v-model="activeIndex2" placeholder="请选择" style="width: 100px" size="mini" @change="getAuditIndex">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </div>
              <div id="c3" ref="chart3" style="width: 100%; height: 250px" v-if="indexData.auditData.length>0"></div>
              <el-empty :image-size="115" v-else></el-empty>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { Index } from '@/components/HospitalDomain/Index.js'
import { Outpatient } from '@/components/DrugDomain/Outpatient.js'
import countTo from 'vue-count-to'
import * as echarts from 'echarts'
export default {
  name: 'index',
  components: {
    countTo,
  },
  data() {
    var index = new Index(this.TokenClient, this.Services.Authorization)
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    return {
      IndexDomain: index,
      OutpatientDomain: outpatient,
      organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
      AllMenus: JSON.parse(sessionStorage.getItem('AllMenus')),
      activeIndex: 0,
      activeIndex2: '0',
      chart: null,
      options: [
        {
          value: '0',
          label: '今天',
        },
        {
          value: '1',
          label: '本周',
        },
        {
          value: '2',
          label: '本月',
        },
        {
          value: '3',
          label: '本年',
        },
      ],
      value: '',
      indexData: {
        balance: 0,
        waitMoney: 0,
        indexDrugIn: [],
        indexDrugReception: [],
        indexIsRefund: 0,
        indexPriceIn: {
          expressMoney: 0,
          patientMoney: 0,
          price: 0,
          tcmDecoctionMoney: 0,
        },
        indexPriceIsNotIn: 0,
        auditData: [],
        makeData: [],
      },
      isShowBlance: false,
      isShowOutpatient: false,
      option: {},
    }
  },
  mounted() {
    this.InitContent()
  },
  methods: {
    InitContent() {
      var list = []
      this.AllMenus.forEach((item) => {
        if (item.isEnable) {
          list.push(item.name)
          if (item.children) {
            item.children.forEach((chile) => {
              list.push(chile.name)
              if (chile.children) {
                chile.children.forEach((chile2) => {
                  list.push(chile2.name)
                })
              }
            })
          }
        }
      })
      if (list.indexOf('财务管理') > -1) {
        this.isShowBlance = true
        this.getBlanceData()
      } else {
        this.isShowBlance = false
      }
      if (list.indexOf('门诊管理') > -1) {
        this.isShowOutpatient = true
        this.getIndexData()
        this.getDoctorReceptionIndex()
        this.getMakeIndex()
        this.getAuditIndex()
        this.getPatientDiagnosesIndex()
      } else {
        this.isShowOutpatient = false
      }
    },
    jumpPage(path) {
      this.$router.push(path)
    },
    getBlanceData() {
      var _this = this
      _this.IndexDomain.IndexData(
        _this.organizationId,
        function (data) {
          _this.indexData.balance = data.data.balance
          _this.indexData.waitMoney = data.data.waitMoney
        },
        function (error) {}
      )
    },
    getMonthDay(dateString) {
      const date = new Date(dateString)
      const month = date.getMonth() + 1 // getMonth() 返回的月份是从 0 开始的
      const day = date.getDate()
      return `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
    },
    initChart1() {
      let chart = echarts.init(this.$refs.chart1)
      const textColor = '#999'
      const seriesData = this.indexData.indexDrugIn.map((item) => item.price)
      const timeList = this.indexData.indexDrugIn.map((item) => item.time)
      let xAxisList = []
      timeList.forEach((item) => {
        xAxisList.push(this.getMonthDay(item))
      })
      const option = {
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            return params && params[0].marker + params[0].name + ': ' + params[0].value + '元'
          },
        },
        grid: {
          top: '30px',
          left: '15px',
          right: '20px',
          bottom: '15px',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: xAxisList,
          axisLabel: {
            // 坐标轴字体颜色
            color: textColor,
            fontSize: 12,
            // rotate: 5,
          },
          axisLine: {
            lineStyle: {
              color: textColor,
            },
          },
          axisTick: {
            // y轴刻度线
            show: true,
          },
          splitLine: {
            // 网格
            show: false,
          },
          boundaryGap: false,
        },
        yAxis: {
          type: 'value',
          min: 0,
          name: '',
          nameTextStyle: {
            color: '#999',
            fontSize: 18,
            padding: [0, 0, 0, 0],
          },
          axisLabel: {
            // 坐标轴字体颜色
            color: textColor,
            fontSize: 12,
          },
          axisLine: {
            show: true,
          },
          axisTick: {
            // y轴刻度线
            show: false,
          },
          splitLine: {
            // 网格
            show: true,
            lineStyle: {
              color: '#CCCCCC',
              type: 'dashed',
            },
          },
        },
        series: [
          {
            name: '',
            type: 'line',
            symbol: 'circle',
            symbolSize: 10,
            z: 1,
            showSymbol: true,
            smooth: true,
            itemStyle: {
              color: '#ffbc16',
            },
            lineStyle: {
              color: '#ffbc16',
            },
            areaStyle: {
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                type: 'linear',
                global: false,
                colorStops: [
                  {
                    offset: 0,
                    color: '#fff9e9',
                  },
                  {
                    offset: 1,
                    color: '#fff9e9',
                  },
                ],
              },
            },
            label: {
              show: true,
              position: 'top',
              textStyle: {
                fontSize: 10,
                color: '#ffbc16',
              },
              formatter: (e) => {
                return e.value ? `${e.value}元` : ''
              },
            },
            data: seriesData,
          },
        ],
      }
      chart.setOption(option)
      window.addEventListener('resize', function () {
        chart.resize()
      })
    },
    initChart2() {
      let chart = echarts.init(this.$refs.chart2)
      const textColor = '#999'
      const seriesData = this.indexData.indexDrugReception.map((item) => item.receptionNum)
      const timeList = this.indexData.indexDrugReception.map((item) => item.time)
      let xAxisList = []
      timeList.forEach((item) => {
        xAxisList.push(this.getMonthDay(item))
      })
      const option = {
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            return params && params[0].marker + params[0].name + ': ' + params[0].value + '次'
          },
        },
        grid: {
          top: '15px',
          left: '15px',
          right: '15px',
          bottom: '15px',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: xAxisList,
          axisLine: {
            lineStyle: {
              color: textColor,
            },
          },
          axisLabel: {
            textStyle: {
              fontFamily: 'Microsoft YaHei',
            },
          },
        },

        yAxis: {
          type: 'value',
          // max:'1200',
          axisLine: {
            show: false,
            lineStyle: {
              color: textColor,
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#ccc',
            },
          },
          axisLabel: {},
        },
        series: [
          {
            name: '',
            type: 'bar',
            barWidth: '20%',
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#248ff7',
                  },
                  {
                    offset: 1,
                    color: '#6851f1',
                  },
                ]),
                barBorderRadius: 11,
              },
            },
            label: {
              show: true,
              position: 'top',
              textStyle: {
                fontSize: 10,
                color: '#248ff7',
              },
              formatter: (e) => {
                return e.value ? `${e.value}次` : ''
              },
            },
            data: seriesData,
          },
        ],
      }
      chart.setOption(option)
      window.addEventListener('resize', function () {
        chart.resize()
      })
    },
    initChart3() {
      let chart = echarts.init(this.$refs.chart3)
      const textColor = '#999'
      let xAxisData = this.indexData.auditData.map((item) => item.name)
      let seriesData = this.indexData.auditData.map((item) => item.value)
      let maxArr = []
      if (this.indexData.auditData.length > 0) {
        maxArr = new Array(this.indexData.auditData.length).fill(this.indexData.auditData[0].value * 1.5)
      }
      let barLinearColors = [
        new echarts.graphic.LinearGradient(0, 1, 1, 1, [
          { offset: 0, color: '#EB3B5A' },
          { offset: 1, color: '#FE9C5A' },
        ]),
        new echarts.graphic.LinearGradient(0, 1, 1, 1, [
          { offset: 0, color: '#FA8231' },
          { offset: 1, color: '#FFD14C' },
        ]),
        new echarts.graphic.LinearGradient(0, 1, 1, 1, [
          { offset: 0, color: '#F7B731' },
          { offset: 1, color: '#FFEE96' },
        ]),
        new echarts.graphic.LinearGradient(0, 1, 1, 1, [
          { offset: 0, color: '#395CFE' },
          { offset: 1, color: '#2EC7CF' },
        ]),
      ]
      function rankBarColor(cData) {
        let tempData = []
        cData.forEach((item, index) => {
          tempData.push({
            value: item,
            itemStyle: {
              color: index > 3 ? barLinearColors[3] : barLinearColors[index],
            },
          })
        })
        return tempData
      }
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: function (params) {
            return params && params[0].marker + params[0].name + ': ' + params[0].value + '个'
          },
        },
        grid: {
          left: '12%',
          top: '10%',
          right: '15px',
          bottom: '15px',
          // containLabel: true,
        },
        xAxis: {
          type: 'value',
          splitLine: { show: false },
          axisLabel: { show: false },
          axisTick: { show: false },
          axisLine: { show: false },
        },
        yAxis: [
          {
            type: 'category',
            inverse: true,
            axisLine: { show: false },
            axisTick: { show: false },
            data: xAxisData,
            axisLabel: {
              rich: {
                nt1: {
                  color: '#fff',
                  backgroundColor: '#EB3B5A',
                  width: 15,
                  height: 15,
                  fontSize: 12,
                  align: 'center',
                  borderRadius: 100,
                  padding: [1, 1, 0, 1],
                },
                nt2: {
                  color: '#fff',
                  backgroundColor: '#FA8231',
                  width: 15,
                  height: 15,
                  fontSize: 12,
                  align: 'center',
                  borderRadius: 100,
                  padding: [1, 1, 0, 1],
                },
                nt3: {
                  color: '#fff',
                  backgroundColor: '#F7B731',
                  width: 15,
                  height: 15,
                  fontSize: 12,
                  align: 'center',
                  borderRadius: 100,
                  padding: [1, 1, 0, 1],
                },
                nt: {
                  color: '#fff',
                  backgroundColor: '#00a9c8',
                  width: 15,
                  height: 15,
                  fontSize: 12,
                  align: 'center',
                  borderRadius: 100,
                  padding: [1, 1, 0, 1],
                },
              },
              formatter: function (value) {
                let idx = xAxisData.indexOf(value) + 1
                if (idx <= 3) {
                  return ['{nt' + idx + '|' + idx + '}'].join('\n')
                } else {
                  return ['{nt|' + idx + '}'].join('\n')
                }
              },
            },
          },
          {
            //名称
            type: 'category',
            offset: -10,
            inverse: true,
            position: 'left',
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: {
              color: '#333',
              align: 'left',
              verticalAlign: 'bottom',
              lineHeight: 32,
              fontSize: 12,
            },
            data: xAxisData,
          },
        ],
        series: [
          {
            zlevel: 1,
            type: 'bar',
            barWidth: 13,
            data: rankBarColor(seriesData),
            itemStyle: {
              normal: {
                barBorderRadius: 30,
              },
            },
            label: {
              show: true,
              fontSize: 10,
              color: '#fff',
              offset: [0, 1],
              formatter: '{c}个',
            },
          },
          {
            type: 'bar',
            barWidth: 14,
            barGap: '-100%',
            itemStyle: {
              normal: {
                barBorderRadius: 30,
                color: 'rgba(0,0,0,0.04)',
              },
            },
            data: maxArr,
          },
        ],
      }
      chart.setOption(option)
      window.addEventListener('resize', function () {
        chart.resize()
      })
    },
    tabClick(i) {
      this.activeIndex = i
      this.getMakeIndex()
    },
    /**
     * 获取门诊数据
     */
    getDoctorReceptionIndex() {
      var _this = this
      _this.IndexDomain.GetDoctorReceptionIndex(
        function (data) {
          _this.indexData.auditDoctorNum = data.data.auditDoctorNum
          _this.indexData.patientNum = data.data.patientNum
          _this.indexData.doctorNum = data.data.doctorNum
        },
        function (error) {}
      )
    },
    getMakeIndex() {
      var _this = this
      _this.OutpatientDomain.GetMakeIndex(
        this.activeIndex,
        function (data) {
          _this.indexData.makeData = data.data
        },
        function (error) {}
      )
    },
    getAuditIndex() {
      var _this = this
      _this.OutpatientDomain.GetAuditIndex(
        this.activeIndex2,
        function (data) {
          _this.indexData.auditData = data.data
          if (_this.indexData.auditData.length > 0) {
            _this.$nextTick(()=>{
              _this.initChart3()
            })
          }
        },
        function (error) {}
      )
    },
    getIndexData() {
      var _this = this
      _this.OutpatientDomain.GetIndex(
        function (data) {
          _this.indexData.indexDrugIn = data.data.indexDrugIn
          _this.indexData.indexPriceIsNotIn = data.data.indexPriceIsNotIn
          _this.indexData.indexPriceIn = data.data.indexPriceIn
          _this.indexData.indexIsRefund = data.data.indexIsRefund
          if (_this.indexData.indexDrugIn.length > 0) {
            _this.$nextTick(()=>{
              _this.initChart1()
            })
          }
        },
        function (error) {}
      )
    },
    getPatientDiagnosesIndex() {
      var _this = this
      _this.IndexDomain.GetPatientDiagnosesIndex(
        function (data) {
          _this.indexData.indexDrugReception = data.data
          if (_this.indexData.indexDrugReception.length > 0) {
            _this.$nextTick(()=>{
              _this.initChart2()
            })
          }
        },
        function (error) {}
      )
    },
  },
}
</script>

<style scoped lang="scss">
.navbar-expand-lg {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar {
  position: relative;
  height: 73px;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.iq-search-bar {
  flex: 1;
  padding: 0 15px;
}

.iq-search-bar .searchbox {
  width: 480px;
  position: relative;
}

.iq-search-bar .searchbox .search-link {
  position: absolute;
  right: 15px;
  top: 2px;
  font-size: 16px;
}

.iq-search-bar .search-input {
  width: 100%;
  padding: 5px 40px 5px 15px;
  border-radius: 10px;
  border: none;
  background: #cbdbf7;
  line-height: 1.8;
}

.header {
  flex: 1;
  text-align: right;
  margin-right: 15px;
}

/*content*/
.center-content {
  height: calc(100vh - 43px);
  overflow-y: auto;
  padding: 80px 0 0;
  overflow-x: hidden;
}
.center-content {
  .title-samll {
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    margin: 25px 0 15px 30px;
  }
  .cart-c {
    padding: 5px;
    box-shadow: 0px 6px 8px 0px rgba(219, 223, 230, 0.15);
    border-radius: 20px;
    .top-right {
      height: 120px;
      .t1 {
        color: #000000;
        font-size: 16px;
        font-weight: 800;
      }
      .num {
        font-weight: bold;
        font-size: 30px;
        color: #f55252;
      }
      .t2 {
        font-weight: 400;
      }
      .dian1 {
        width: 7px;
        height: 7px;
        background: #e14812;
        border-radius: 50%;
      }
      .dian2 {
        width: 7px;
        height: 7px;
        background: #ffae00;
        border-radius: 50%;
      }
      .dian3 {
        width: 7px;
        height: 7px;
        background: #12e146;
        border-radius: 50%;
      }
      .dian4 {
        width: 7px;
        height: 7px;
        background: #4b64c3;
        border-radius: 50%;
      }
      .n1 {
        color: #666;
        font-size: 14px;
        margin: 0 10px;
        font-weight: 500;
      }
      .num2 {
        color: #000000;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .box {
    border-radius: 12px;
    height: 120px;
    font-size: 16px;
  }
  .cursor {
    cursor: pointer;
  }
  .b1 {
    background: #3f75ff;
    width: 48%;
    padding: 10px 15px;
    color: #ffffff;
    font-size: 15px;
    .num {
      font-weight: bold;
      font-size: 30px;
    }

    .bottom-btn {
      height: 30px;
      line-height: 20px;

      span {
        font-size: 14px;
        margin-right: 10px;
        color: #fff7be;
        cursor: pointer;
      }
    }
  }
  .b2 {
    background: #fffbeb;
    border: 1px solid #f1dd8b;
    width: 48%;
    font-size: 16px;
    color: #000000;
    .num {
      font-weight: bold;
      font-size: 30px;
    }
  }
  .bc {
    width: 32%;
    padding: 15px 30px;
    .t3 {
      font-weight: 500;
      font-size: 15px;
      color: #666666;
      height: 50px;
      line-height: 50px;
    }
    .t4 {
      font-weight: 800;
      font-size: 25px;
      line-height: 1.5;
      color: #000000;
    }
  }
  .b3 {
    background: #e4eeff;
    border: 1px solid #c9ddff;
  }
  .b4 {
    background: #eee5ff;
    border: 1px solid #dac6ff;
  }
  .b5 {
    background: #d7f0e2;
    border: 1px solid #b3e0c7;
  }
  .rightContent {
    .cart-c {
      margin-bottom: 15px;
    }
    .box {
      .num {
        font-weight: bold;
        font-size: 30px;
      }
    }
    .cTitle {
      font-weight: 800;
      font-size: 18px;
      color: #000000;
    }
  }
  .line1 {
    width: 1px;
    height: 73px;
    border-left: 1px dashed #cccccc;
  }
  .tabName {
    font-weight: 400;
    font-size: 16px;
    color: #666666;
    cursor: pointer;
    margin-right: 10px;
  }
  .currentTitle {
    font-weight: 800;
    font-size: 18px;
    color: #333333;
  }
  .currentTitle::after {
    content: '';
    display: block;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background: black;
    margin-left: 15px;
    margin-top: -5px;
  }
}

::v-deep .el-table th.is-leaf {
  /* 去除上边框 */
  border: none !important;
}

::v-deep .el-table::before {
  /* 去除下边框 */
  height: 0 !important;
}
</style>
